<template>
  <section class="Integrations__container">
    <h2 class="Integrations__title">Prima.Law Payments Settings</h2>
    <p class="Integrations__description">
      Manage your credit card payments options
    </p>

    <div class="Integrations__card">
      <h3 class="Integrations__title">Bank Account Connections</h3>
      <p>
        Connect each Prima.Law bank account to the corresponding PrimaPay
        account
      </p>

      <!-- ANT DESIGN TABLE -->

      <ATable
        :columns="columns"
        :data-source="data.accounts"
        :pagination="{ pageSize: 4 }"
        rowKey="_id"
      >
        <template #type="{ text }">
          <span>
            {{ text === 'operatingAccount' ? 'Operating' : 'Trust' }}
          </span>
        </template>
        <template #status="{ record }" :rowKey="`status${record._id}`">
          <span>
            <ATag
              :color="
                !!record.lexChargeAccountId &&
                data.primaPayAccounts.some(
                  primaPayAccountData =>
                    !!primaPayAccountData.merchantId &&
                    primaPayAccountData.merchantId ===
                      record.lexChargeAccountId,
                )
                  ? 'green'
                  : 'red'
              "
            >
              {{
                getPrimaPayStatus(
                  !!record.lexChargeAccountId &&
                    data.primaPayAccounts.some(
                      primaPayAccountData =>
                        !!primaPayAccountData.merchantId &&
                        primaPayAccountData.merchantId ===
                          record.lexChargeAccountId,
                    ),
                )
              }}
            </ATag>
          </span>
        </template>
        <template
          #select="{ record }"
          :rowKey="`primaPayAccounts${record._id}`"
        >
          <span>
            <ASelect
              v-model:value="record.primaPayAccountSelected"
              placeholder="Select Bank Account"
              style="width: 100%"
              allowClear
              @change="handleAccount($event, record)"
            >
              <ASelectOption
                v-for="primaPayAccount of setTypeAccounts(record.type)"
                :key="primaPayAccount"
                @mouseenter="showBankAccountDetails(primaPayAccount)"
                :disabled="primaPayAccount.registerState !== 'ACTIVE'"
                :style="
                  primaPayAccount.registerState !== 'ACTIVE'
                    ? 'opacity: 0.5'
                    : ''
                "
              >
                <APopover placement="leftTop">
                  <template #title>
                    <span>Details</span>
                  </template>
                  <template #content v-if="primaPayAccount.details">
                    <p>
                      <b>Owner Name:</b>
                      {{ primaPayAccount.details.bankAccountOwnerName }}
                    </p>
                    <p>
                      <b>Account Number:</b>
                      {{ primaPayAccount.details.bankAccountNumber }}
                    </p>
                  </template>
                  <template #content v-else>
                    <b>State: </b>
                    <span style="color: red">{{
                      primaPayAccount.registerState
                    }}</span>
                  </template>
                  <label style="color: blue">{{
                    primaPayAccount.accountName
                  }}</label>
                </APopover>
              </ASelectOption>
            </ASelect>
          </span>
        </template>
      </ATable>
    </div>

    <img
      style="position: absolute; margin-top: 10px; width: 100px"
      src="../assets/images/primaPay/primaPay.png"
      alt=""
    />

    <div class="Integrations__actions">
      <div class="">
        <AButton @click="closeModal">Close</AButton>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { IntegrationsService } from '@/services';
import { Account, PrimaPayAccounts } from '@/types/integrations.type';
import { defineComponent, reactive } from 'vue';

interface State {
  accounts: Account[];
  primaPayAccounts: PrimaPayAccounts[];
}

const columns = [
  {
    title: 'Prima Bank Account',
    dataIndex: 'accountName',
    key: 'accountName',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    slots: { customRender: 'type' },
  },
  {
    title: 'Status',
    dataIndex: 'primaPayAccount',
    key: 'status',
    slots: { customRender: 'status' },
  },
  {
    title: 'PrimaPay Bank Account',
    dataIndex: '_id',
    key: 'select',
    slots: { customRender: 'select' },
  },
];

export default defineComponent({
  setup() {
    const data = reactive<State>({
      accounts: [],
      primaPayAccounts: [],
    });

    /** Methods */

    /** primaPay bank account status */
    const getPrimaPayStatus = (status: boolean) => {
      return status ? 'Connected' : 'Disconnected';
    };

    const renderAccountName = (accountName: string) => {
      if (accountName.length > 25) return accountName.substr(0, 25) + '...';
      return accountName;
    };

    const showBankAccountDetails = async (
      primaPayAccount: PrimaPayAccounts,
    ) => {
      if (
        !primaPayAccount.details &&
        primaPayAccount.registerState === 'ACTIVE' &&
        primaPayAccount.merchantId !== null
      ) {
        try {
          const primaPayDetails = await IntegrationsService.getDetailsPrimaPayAccount(
            primaPayAccount.merchantId,
          );

          primaPayAccount.details = primaPayDetails;
        } catch (error) {
          console.log(error);
        }
      }
    };

    /** Set info for v-model if primapay account selected */
    const setSelectedPrimaPayAccounts = () => {
      data.accounts.forEach(account => {
        if (
          data.primaPayAccounts.some(
            primaPayAccount =>
              primaPayAccount.merchantId === account.lexChargeAccountId,
          )
        ) {
          data.primaPayAccounts.forEach(primaPayAccount => {
            if (
              !!primaPayAccount.merchantId &&
              primaPayAccount.merchantId === account.lexChargeAccountId
            ) {
              account.primaPayAccountSelected = primaPayAccount;

              showBankAccountDetails(primaPayAccount);
            }
          });
        }
      });
    };

    const executeRequests = async () => {
      try {
        const {
          accounts,
          primaPayAccounts,
        } = await IntegrationsService.getDualAccounts();
        data.accounts = accounts;
        data.primaPayAccounts = primaPayAccounts;

        setSelectedPrimaPayAccounts();
      } catch (error) {
        console.error(error);
      }
    };

    executeRequests();

    /** Event from options to sync accounts with primapay */
    const syncAccount = (
      primaPayAccount: any,
      primaAccount: any,
      sync: boolean,
    ) => {
      return new Promise((resolve, reject) => {
        // Mutation sync account
        try {
          IntegrationsService.syncAccount({
            lexChargeAccountId: primaPayAccount.merchantId,
            primaAccountId: primaAccount._id,
            sync,
          }).then(primaAccountData => {
            if (primaAccountData && primaAccountData !== null) {
              // Set Prima account
              data.accounts[
                data.accounts
                  .map(account => account._id)
                  .indexOf(primaAccountData.primaAccountId)
              ].lexChargeAccountId = primaAccountData.sync
                ? primaAccountData.lexChargeAccountId
                : null;

              if (!sync) {
                data.accounts[
                  data.accounts
                    .map(account => account._id)
                    .indexOf(primaAccountData.primaAccountId)
                ].primaPayAccountSelected = null;
              }

              // data.accounts = Object.assign([], data.accounts, data.accounts);

              setSelectedPrimaPayAccounts();

              resolve(true);
            }
          });
        } catch (error) {
          console.error(error);
          reject(false);
        }
      });
    };

    /** Sync & unsync primapay bank accounts */
    const handleAccount = async (
      primaPayAccount: PrimaPayAccounts,
      primaAccount: Account,
    ) => {
      if (primaAccount.lexChargeAccountId) {
        const prevPrimaPayAccount = {
          merchantId: primaAccount.lexChargeAccountId,
        };

        await syncAccount(prevPrimaPayAccount, primaAccount, false);
      }

      if (primaPayAccount) syncAccount(primaPayAccount, primaAccount, true);
    };

    const setTypeAccounts = (type: string) => {
      type = type === 'trustAccount' ? 'trust' : 'operating';

      return data.primaPayAccounts.filter(
        primaPayAccount => primaPayAccount.type === type,
      );
    };

    const closeModal = () => {
      window.top.postMessage(
        'close integrations',
        process.env.VUE_APP_PRIMA_URL,
      );
    };

    return {
      data,
      columns,
      getPrimaPayStatus,
      syncAccount,
      closeModal,
      renderAccountName,
      handleAccount,
      showBankAccountDetails,
      setTypeAccounts,
    };
  },
});
</script>

<style lang="scss">
.Integrations {
  &__container {
    padding: 1rem;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 0;
  }

  &__card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1rem;
    overflow-x: auto;

    &-table {
      width: 100%;
      min-width: 700px;

      th,
      td {
        padding: 0.8rem;
      }

      tr:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__save-settings-btn {
    margin-right: 1rem;
  }
}
</style>
