
import { IntegrationsService } from '@/services';
import { Account, PrimaPayAccounts } from '@/types/integrations.type';
import { defineComponent, reactive } from 'vue';

interface State {
  accounts: Account[];
  primaPayAccounts: PrimaPayAccounts[];
}

const columns = [
  {
    title: 'Prima Bank Account',
    dataIndex: 'accountName',
    key: 'accountName',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    slots: { customRender: 'type' },
  },
  {
    title: 'Status',
    dataIndex: 'primaPayAccount',
    key: 'status',
    slots: { customRender: 'status' },
  },
  {
    title: 'PrimaPay Bank Account',
    dataIndex: '_id',
    key: 'select',
    slots: { customRender: 'select' },
  },
];

export default defineComponent({
  setup() {
    const data = reactive<State>({
      accounts: [],
      primaPayAccounts: [],
    });

    /** Methods */

    /** primaPay bank account status */
    const getPrimaPayStatus = (status: boolean) => {
      return status ? 'Connected' : 'Disconnected';
    };

    const renderAccountName = (accountName: string) => {
      if (accountName.length > 25) return accountName.substr(0, 25) + '...';
      return accountName;
    };

    const showBankAccountDetails = async (
      primaPayAccount: PrimaPayAccounts,
    ) => {
      if (
        !primaPayAccount.details &&
        primaPayAccount.registerState === 'ACTIVE' &&
        primaPayAccount.merchantId !== null
      ) {
        try {
          const primaPayDetails = await IntegrationsService.getDetailsPrimaPayAccount(
            primaPayAccount.merchantId,
          );

          primaPayAccount.details = primaPayDetails;
        } catch (error) {
          console.log(error);
        }
      }
    };

    /** Set info for v-model if primapay account selected */
    const setSelectedPrimaPayAccounts = () => {
      data.accounts.forEach(account => {
        if (
          data.primaPayAccounts.some(
            primaPayAccount =>
              primaPayAccount.merchantId === account.lexChargeAccountId,
          )
        ) {
          data.primaPayAccounts.forEach(primaPayAccount => {
            if (
              !!primaPayAccount.merchantId &&
              primaPayAccount.merchantId === account.lexChargeAccountId
            ) {
              account.primaPayAccountSelected = primaPayAccount;

              showBankAccountDetails(primaPayAccount);
            }
          });
        }
      });
    };

    const executeRequests = async () => {
      try {
        const {
          accounts,
          primaPayAccounts,
        } = await IntegrationsService.getDualAccounts();
        data.accounts = accounts;
        data.primaPayAccounts = primaPayAccounts;

        setSelectedPrimaPayAccounts();
      } catch (error) {
        console.error(error);
      }
    };

    executeRequests();

    /** Event from options to sync accounts with primapay */
    const syncAccount = (
      primaPayAccount: any,
      primaAccount: any,
      sync: boolean,
    ) => {
      return new Promise((resolve, reject) => {
        // Mutation sync account
        try {
          IntegrationsService.syncAccount({
            lexChargeAccountId: primaPayAccount.merchantId,
            primaAccountId: primaAccount._id,
            sync,
          }).then(primaAccountData => {
            if (primaAccountData && primaAccountData !== null) {
              // Set Prima account
              data.accounts[
                data.accounts
                  .map(account => account._id)
                  .indexOf(primaAccountData.primaAccountId)
              ].lexChargeAccountId = primaAccountData.sync
                ? primaAccountData.lexChargeAccountId
                : null;

              if (!sync) {
                data.accounts[
                  data.accounts
                    .map(account => account._id)
                    .indexOf(primaAccountData.primaAccountId)
                ].primaPayAccountSelected = null;
              }

              // data.accounts = Object.assign([], data.accounts, data.accounts);

              setSelectedPrimaPayAccounts();

              resolve(true);
            }
          });
        } catch (error) {
          console.error(error);
          reject(false);
        }
      });
    };

    /** Sync & unsync primapay bank accounts */
    const handleAccount = async (
      primaPayAccount: PrimaPayAccounts,
      primaAccount: Account,
    ) => {
      if (primaAccount.lexChargeAccountId) {
        const prevPrimaPayAccount = {
          merchantId: primaAccount.lexChargeAccountId,
        };

        await syncAccount(prevPrimaPayAccount, primaAccount, false);
      }

      if (primaPayAccount) syncAccount(primaPayAccount, primaAccount, true);
    };

    const setTypeAccounts = (type: string) => {
      type = type === 'trustAccount' ? 'trust' : 'operating';

      return data.primaPayAccounts.filter(
        primaPayAccount => primaPayAccount.type === type,
      );
    };

    const closeModal = () => {
      window.top.postMessage(
        'close integrations',
        process.env.VUE_APP_PRIMA_URL,
      );
    };

    return {
      data,
      columns,
      getPrimaPayStatus,
      syncAccount,
      closeModal,
      renderAccountName,
      handleAccount,
      showBankAccountDetails,
      setTypeAccounts,
    };
  },
});
